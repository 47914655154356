import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatPercent } from '@shared/lib/numbers';
import { ReactComponent as LowRiskIcon } from '@icons/wolfkit-light/emoji-smile-light.svg';
import { ReactComponent as AverageRiskIcon } from '@icons/wolfkit-light/emoji-meh-icon.svg';
import { ReactComponent as HighRiskIcon } from '@icons/wolfkit-light/emoji-frown-light.svg';
import defineDrawdownRisk from '@features/portfolio/ui/PortfolioOverview/utils';
const DrawdownTitleContainer = styled.div(props => ({
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    marginBottom: props.theme.spacing_sizes.l,
}));
const DrawdownInner = styled.div(() => ({
    display: 'flex',
    width: '100%',
}));
const DrawdownEstimation = styled.div();
const SectionTitleText = styled.span(() => ({
    flex: '0 0 auto',
    fontSize: 16,
    fontWeight: 500,
}));
const SectionTitleFiller = styled.span(() => ({
    display: 'flex',
    flex: '1 1 auto',
    height: '1px',
    margin: '0px 2px 4px',
    alignSelf: 'end',
    backgroundImage: 'linear-gradient(to right, #7E868C 10%, rgba(255, 255, 255, 0) 0%)',
    backgroundPosition: 'top',
    backgroundSize: '8px 2px',
    backgroundRepeat: 'repeat-x',
}));
const SectionTitleValue = styled.span(() => ({
    flex: '0 0 auto',
    alignSelf: 'start',
    fontWeight: 600,
    fontSize: 20,
}));
const defineRiskTextColor = (theme, risk) => {
    switch (risk) {
        case 'low':
            return theme.palette.success.main;
        case 'average':
            return theme.palette.warning.main;
        case 'high':
            return theme.palette.error.main;
        default:
            return '';
    }
};
const DrawdownRiskContainer = styled.span(props => ({
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs / 2,
    fontSize: props.theme.baseSize * 2,
    color: defineRiskTextColor(props.theme, props.risk),
}));
const DrawdownRisk = ({ value = undefined }) => {
    const { t } = useTranslation();
    const { icon, text, risk } = useMemo(() => {
        const res = defineDrawdownRisk(value);
        switch (res) {
            case 'low':
                return {
                    icon: _jsx(LowRiskIcon, {}),
                    text: t('portfolio_overview.risks.low', { ns: 'common' }),
                    risk: 'low',
                };
            case 'average':
                return {
                    icon: _jsx(AverageRiskIcon, {}),
                    text: t('portfolio_overview.risks.average', { ns: 'common' }),
                    risk: 'average',
                };
            case 'high':
                return {
                    icon: _jsx(HighRiskIcon, {}),
                    text: t('portfolio_overview.risks.high', { ns: 'common' }),
                    risk: 'high',
                };
            default:
                return { icon: null, text: '' };
        }
    }, [value]);
    return (_jsxs(DrawdownRiskContainer, { risk: risk, children: [icon, text] }));
};
const DrawdownTitle = ({ title = null, value = undefined }) => (_jsxs(DrawdownTitleContainer, { children: [_jsxs(DrawdownInner, { children: [_jsx(SectionTitleText, { children: title }), _jsx(SectionTitleFiller, {}), _jsx(SectionTitleValue, { children: formatPercent(value || 0) })] }), _jsx(DrawdownEstimation, { children: _jsx(DrawdownRisk, { value: value }) })] }));
export default DrawdownTitle;
